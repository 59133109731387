import React from 'react'
// import { FormattedMessage } from 'react-intl'
import PoweredBy from '../PoweredBy/PoweredBy'

import logo from 'src/static/svg/logo-w.svg'
import './Footer.sass'

const Footer = () => {
  return (
    <footer id="colophon">
      <PoweredBy />
      <section>
        <img className="logo" src={logo} alt="Nuevos retos logo" />
      </section>
    </footer>
  )
}

export default Footer
