import React, { useState } from 'react'
// import { FormattedMessage } from 'react-intl'
import CalendarFormation from './CalendarFormation/CalendarFormation'
import CalendarHeader from './CalendarHeader/CalendarHeader'
import CalendarSideBar from './CalendarSideBar/CalendarSideBar'
import data from 'src/data'

import './Calendar.sass'

const Calendar = ({ onView = false }) => {
  const [cityId, setCityId] = useState(0)
  const {cities} = data
  const cityData = cities.find(c => c.id === cityId)

  return (
    <section id="calendar" className={onView ? 'onView' : ''}>
      <div className="container">
        <CalendarHeader />
        <div className="split">
          <div className="item item-left">
            <CalendarSideBar
              cities={cities}
              cityData={cityData}
              setCityId={setCityId}
            />
          </div>
          <div className="item item-right">
            <img src={'/svg/map/' + cityData.mapSrc + '?'} alt="map illustration" />
          </div>
        </div>
        <CalendarFormation />
      </div>
    </section>
  )
}

export default Calendar
