import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import data from 'src/data'
import Loading from '../Loading/Loading'

import './Register.sass'

const Register = ({ onView = false }) => {
  const { cities } = data
  const cityNames = cities.map(c => c.name)
  const steps = ['pre-registration', 'preselection', 'interview', 'confirmation']
  const [status, setStatus] = useState('')
  const intl = useIntl()

  const fetchApiSubmit = async (event) => {
    event.preventDefault()
    if (status === 'loading') return
    setStatus('loading')
    try {
      const res = await fetch(process.env.NUEVOS_RETOS_API_BASE + "/api/submit", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          companyName: event.target["company-name"].value,
          companyNif: event.target["company-nif"].value,
          companySector: event.target["company-sector"].value,
          companyAnnualRevenue: event.target["company-annual-revenue"].value,
          companyAnnualExport: event.target["company-annual-export"].value,
          companyWeb: event.target["company-web"].value,
          contactName: event.target["contact-name"].value,
          contactSurnames: event.target["contact-surnames"].value,
          contactEmail: event.target["contact-email"].value,
          contactPhone: event.target["contact-phone"].value,
          contactPosition: event.target["contact-position"].value,
          contactCity: event.target["contact-city"].value,
          contactHow: event.target["contact-how"].value
        })
      })
      console.log(res.status)
      setStatus('success')
    } catch (err) {
      console.error(err)
      setStatus('error')
    }
  }
  const form =
    <form onSubmit={fetchApiSubmit}>
      <h1 className="title">
        <FormattedMessage id="register.title" />
      </h1>
      <div className="row">
        <label>
          <input
            required
            type="text"
            name="company-name"
            autoComplete="organization"
            placeholder={intl.formatMessage({ id: 'register.company-name' })}
          />
        </label>
        <label>
          <input
            type="text"
            required
            name="company-nif"
            autoComplete="off"
            placeholder={intl.formatMessage({ id: 'register.company-nif' })}
          />
        </label>
        <label>
          <input
            type="text"
            required
            name="company-sector"
            autoComplete="off"
            placeholder={intl.formatMessage({ id: 'register.company-sector' })}
          />
        </label>
      </div>
      <div className="row">
        <label>
          <input
            type="number"
            required
            name="company-annual-revenue"
            placeholder={intl.formatMessage({ id: 'register.company-annual-revenue' })}
          />
        </label>
        <label>
          <input
            type="number"
            required
            name="company-annual-export"
            placeholder={intl.formatMessage({ id: 'register.company-annual-export' })}
          />
        </label>
      </div>
      <div className="row">
        <label>
          <input
            type="text"
            required
            autoComplete="url"
            name="company-web"
            placeholder={intl.formatMessage({ id: 'register.company-web' })}
          />
        </label>
        <select required defaultValue="" name="contact-city">
          <option disabled value="">
            {intl.formatMessage({ id: 'register.contact-city' })}
          </option>
          {cityNames.map(c => {
            return <option value={c} key={c}>{c}</option>
          })}
        </select>
      </div>
      <div className="row">
        <label>
          <input
            type="text"
            required
            autoComplete="name"
            name="contact-name"
            placeholder={intl.formatMessage({ id: 'register.contact-name' })}
          />
        </label>
        <label>
          <input
            type="text"
            required
            name="contact-surnames"
            autoComplete="family-name"
            placeholder={intl.formatMessage({ id: 'register.contact-surname' })}
          />
        </label>
      </div>
      <div className="row">
        <label>
          <input
            type="tel"
            autoComplete="tel"
            required
            name="contact-phone"
            placeholder={intl.formatMessage({ id: 'register.contact-phone' })}
          />
        </label>
        <label>
          <input
            type="email"
            required
            name="contact-email"
            autoComplete="email"
            placeholder={intl.formatMessage({ id: 'register.contact-email' })}
          />
        </label>
      </div>
      <div className="row">
        <label>
          <input
            type="text"
            required
            name="contact-position"
            autoComplete="organization-title"
            placeholder={intl.formatMessage({ id: 'register.contact-position' })}
          />
        </label>
        <label>
          <input
            type="text"
            name="contact-how"
            required
            placeholder={intl.formatMessage({ id: 'register.contact-how' })}
          />
        </label>
      </div>
      <p><FormattedMessage id="register.text" /></p>
      <button type="submit" className="filled"><FormattedMessage id="send" /></button>
    </form>

  return (
    <section id="register" className={onView ? 'onView' : ''}>
      <header className="header-steps">
        {steps.map((s, i) =>
          <div key={s} className={`header-step step-${s}`}>
            <span className={`icon icon-${s}`} />
            <span className="name"><FormattedMessage id={`register.step-${s}`} /></span>
            {i + 1 !== steps.length && <span className="icon icon-arrow" />}
          </div>
        )}
      </header>
      {status === 'loading'
        ? <Loading />
        : form
      }
    </section>
  )
}

export default Register
