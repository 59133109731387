import React from 'react'
import { FormattedMessage } from 'react-intl'
import data from 'src/data'

import './CalendarFormation.sass'

const CalendarFormation = () => {
  const { cities } = data

  return (
    <div className="calendar-formation">
      <h3><FormattedMessage id="calendar.calendar-formation.title" /></h3>
      <ul>
        {cities.map(c => {
          return (
            <li key={c.id}>
              <strong className="name">{c.name}</strong>
              <div className="date">
                <span className="icon icon-calendar" />
                <span>{c.start}</span>
                <span className="only-mobile">{c.year}</span>
              </div>
              <span className="year only-desktop">{c.year}</span>
            </li>
          )
        })}
      </ul>

    </div>
  )
}

export default CalendarFormation
