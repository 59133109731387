import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { useAtTop } from '@gluedigital/scrollastic'

import logo from 'src/static/svg/logo.svg'
import logo2 from 'src/static/svg/logo-2.svg'
import logoW from 'src/static/svg/logo-w.svg'
import './Header.sass'

interface HeaderProps {
  scrollable?: any
  page?: string
}

const Header = ({ scrollable, page }: HeaderProps) => {
  const atTop = useAtTop(scrollable)
  const [open, setOpen] = useState(false)

  return (
    <header id="masthead" className={`${atTop ? '' : 'active'} ${page}`}>
      <div className="container">
        <nav>
          <img
            className="logo"
            src={atTop && page === 'home' ? logo2 : open ? logoW : logo}
            alt="Nuevos retos logo"
          />
          <button className="menu-button" onClick={() => setOpen(!open)}>
            <span className={`icon icon-${open ? 'cross' : 'menu'}`}></span>
          </button>
          <ul className={`menu ${open ? 'open' : ''}`}>
            <li>
              <NavLink activeClassName="selected" to="/" className="link" exact>
                <FormattedMessage id="header.link.home" />
              </NavLink>
            </li>
            <li>
              <NavLink activeClassName="selected" to="/program" className="link">
                <FormattedMessage id="header.link.program" />
              </NavLink>
            </li>
            {/* <li>
              <NavLink activeClassName="selected" to="/experts" className="link">
                <FormattedMessage id="header.link.experts" />
              </NavLink>
            </li> */}
            <li>
              <NavLink activeClassName="selected" to="/calendar" className="link">
                <FormattedMessage id="header.link.calendar" />
              </NavLink>
            </li>
            <li onClick={() => setTimeout(() => setOpen(false), 500)}>
              <a href="#register" className="link link-subscribe">
                <FormattedMessage id="header.link.subscribe" />
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  )
}

export default Header
