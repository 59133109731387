import React from 'react'
import { FormattedMessage } from 'react-intl'

import './CalendarHeader.sass'

const CalendarHeader = () => {
  return (
    <header className="calendar-header">
      <ul>
        <li>
          <span className="icon icon-location" />
          <span>
            <FormattedMessage id="calendar.calendar-header.cities" />
          </span>
        </li>
        <li>
          <span className="icon icon-calendar" />
          <span>
            <FormattedMessage id="calendar.calendar-header.sessions" />
          </span>
        </li>
        <li>
          <span className="icon icon-user-messagge" />
          <span>
            <FormattedMessage id="calendar.calendar-header.face" />
          </span>
        </li>
        <li>
          <span className="icon icon-clock" />
          <span>
            <FormattedMessage id="calendar.calendar-header.duration" />
          </span>
        </li>
      </ul>
    </header>
  )
}

export default CalendarHeader
