import React, { useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import { useScrollOver } from '@gluedigital/scrollastic'
import Header from 'src/components/Header/Header'
import Footer from 'src/components/Footer/Footer'
import Register from 'src/components/Register/Register'
import Calendar from 'src/components/Calendar/Calendar'

import './CalendarPage.sass'

const CalendarPage = () => {
  const scrollable = useRef(null)
  const registerSection = useScrollOver('#register', scrollable)
  const calendarSection = useScrollOver('#calendar', scrollable)

  return (
    <div id="calendar-page" key="calendar-page" ref={scrollable} className="page">
      <Header scrollable={scrollable} />
      <section id="info">
        <h1><FormattedMessage id="calendar-page.title" /></h1>
      </section>
      <Calendar onView={calendarSection > 0.2} />
      <Register onView={registerSection > 0.2} />
      <Footer />
    </div>
  )
}

export default CalendarPage
