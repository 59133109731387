import face from 'src/static/svg/banner/face.svg'
import intensive from 'src/static/svg/banner/intensive.svg'
import ceo from 'src/static/svg/banner/ceo.svg'
import experts from 'src/static/svg/banner/experts.svg'
import free from 'src/static/svg/banner/free.svg'
import internationalization from 'src/static/svg/ceo/internationalization.svg'
import digitization from 'src/static/svg/ceo/digitization.svg'
import innovation from 'src/static/svg/ceo/innovation.svg'
import sustainability from 'src/static/svg/ceo/sustainability.svg'
import leadership from 'src/static/svg/ceo/leadership.svg'
import financing from 'src/static/svg/ceo/financing.svg'
import motor from 'src/static/svg/why/motor.svg'
import ponder from 'src/static/svg/why/ponder.svg'
import positive from 'src/static/svg/why/positive.svg'
import excellence from 'src/static/svg/why/excellence.svg'


export default {
    face,
    intensive,
    ceo,
    experts,
    internationalization,
    digitization,
    innovation,
    sustainability,
    free,
    leadership,
    financing,
    motor,
    ponder,
    positive,
    excellence
}
