import React from 'react'
import { FormattedMessage } from 'react-intl'
import MarkdownMessage from '@gluedigital/markdown-message'

import './Who.sass'

import icex from 'src/static/svg/who/icex.svg'
import cesce from 'src/static/svg/who/cesce.svg'
import ico from 'src/static/svg/who/adigital-ico.svg'

const Who = ({onView}) => {

  return (
    <section id="who-are" className={onView ? 'onView' : ''}>
      <div className="container">
        <div className="split">
          <div className="item item-left left">
            <h2 className="subtitle">
              <FormattedMessage id="home.general.subtitle" />
            </h2>
            <h1 className="title">
              <FormattedMessage id="home.who-are.title" />
            </h1>
            <p>
              <MarkdownMessage id="home.who-are.text-1" />
            </p>
            <p>
              <FormattedMessage id="home.who-are.text-2" />
            </p>
          </div>
          <div className="item item-right right">
            <div className="who-are-grid">
              <img src={icex} alt="icex logo" />
              <img src={cesce} alt="cesce logo" />
              <img src={ico} alt="adigital + ico logo" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Who
