import React, { useEffect, useState }  from 'react'

import './CalendarSideBar.sass'

const CalendarSideBar = ({ setCityId, cityData, cities }) => {
  const [month, setMonth] = useState(0)
  const maxMonths = cityData.months.length || 0

  useEffect(() => {
    setMonth(0)
  }, [cityData])

  return (
    <aside className="calendar-sidebar">
      <div className="wrapper">
        <select
          defaultValue={cityData.name}
          onChange={e => setCityId(+e.target.value)}
        >
          {cities.map(c => {
            return <option value={c.id} key={c.id}>{c.name}</option>
          })}
        </select>
        <div className="calendar-info">
          <div>
            <span className="icon icon-calendar" />
            <span>{cityData.year}</span>
          </div>
          <div>
            <span className="icon icon-clock" />
            <span>{cityData.hour}</span>
          </div>
        </div>
        <div className="calendar-image">
          <header>
            <span
              onClick={() => month > 0 && setMonth(month - 1)}
              className={`icon icon-angle-left ${0 === month ? 'disabled' : ''}`}
            />
            <span>{cityData.months[month]}</span>
            <span
              onClick={() => month < (maxMonths - 1) && setMonth(month + 1)}
              className={`icon icon-angle-right ${(maxMonths - 1) === month ? 'disabled' : ''}`}
            />
          </header>
          <img
            src={`/svg/calendar/${cityData.id}/${cityData.months[month]}.svg?`}
            alt="calendar illustration"
          />
        </div>
      </div>
    </aside>
  )
}

export default CalendarSideBar
