import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Home from './Home/Home'
import NotFound from './NotFound/NotFound'
import ErrorBoundary from 'src/components/ErrorBoundary/ErrorBoundary'
import { ModalWrapper, Modal } from '@gluedigital/modal'
import Program from './Program/Program'
import CalendarPage from './CalendarPage/CalendarPage'

const App = () => {

  return (
    <ModalWrapper>
      <main id="app">
        <ErrorBoundary>
          <Switch>
            <Route path="/" exact>
              <Home />
            </Route>
            <Route path="/program" exact>
              <Program />
            </Route>
            <Route path="/calendar" exact>
              <CalendarPage />
            </Route>
            <Route>
              <NotFound />
            </Route>
          </Switch>
        </ErrorBoundary>
        <Modal />
      </main>
    </ModalWrapper>
  )
}

export default App
