import React from 'react'
import { FormattedMessage } from 'react-intl'

import './Intro.sass'

const Intro = ({ onView }) => {

  return (
    <section id="intro" className={onView ? 'onView' : ''}>
      <div className="container">
        <h2 className="subtitle">
          <FormattedMessage id="home.general.subtitle" />
        </h2>
        <h1 className="title">
          <FormattedMessage id="home.intro.title" />
        </h1>
        <p>
          <FormattedMessage id="home.intro.text" />
        </p>
      </div>
    </section>
  )
}

export default Intro
