import React from 'react'
import { FormattedMessage } from 'react-intl'
import images from '../images'

import './Why.sass'

const Why = ({onView}) => {
  const whyCards = [
    'motor',
    'ponder',
    'positive',
    'excellence'
  ]

  return (
    <section id="why-participate" className={onView ? 'onView' : ''}>
      <div className="container">
        <h1 className="title">
          <FormattedMessage id="home.why-participate.title" />
        </h1>
        <ul className="why-participate-grid">
          {whyCards.map(c =>
            <li key={c}>
              <img src={images[c]} alt={`icon ${c}`} />
              <p>
                <FormattedMessage id={`home.why-participate.${c}`} />
              </p>
            </li>
          )}
        </ul>
      </div>
    </section>
  )
}

export default Why
