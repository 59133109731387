import React from 'react'
import { FormattedMessage } from 'react-intl'

import './RoadMap.sass'

const RoadMap = ({percent = 0}) => {
  const sections = ['transformation', 'capacities', 'vision', 'networking', 'route']
  const adjustPercent = percent < 0.85 ? (percent + 0.15) : 1
  return (
    <div id="roadmap">
      <div className="horizontal">
        <div className="container">
          <div className="bar">
            <div style={{width: (adjustPercent * 100) + '%' }} className="percentage" />
            <div style={{left: (adjustPercent * 100) + '%' }} className="icon icon-angle-right" />
          </div>
          <div className="roadmap-grid">
            {sections.map((s, i) => {
              const active = i < adjustPercent * 5
              return (
                <div
                  key={s}
                  className={`section-cell cell-${s} ${active ? 'active' : ''}`}
                >
                  <h2><FormattedMessage id={`roadmap.section-${s}.title`} /></h2>
                  <p><FormattedMessage id={`roadmap.section-${s}.text`} /></p>
              </div>
              )
            })}
          </div>
        </div>
      </div>
      <div className="vertical">
        <div className="bar">
          <div style={{height: (adjustPercent * 100) + '%' }} className="percentage" />
          <div style={{top: (adjustPercent * 100) + '%' }} className="icon icon-angle-down" />
        </div>
        <div className="roadmap-grid">
          {sections.map((s, i) => {
            const active = i < adjustPercent * 5
            return (
              <div
                key={s}
                className={`section-cell cell-${s} ${active ? 'active' : ''}`}
              >
                <h2><FormattedMessage id={`roadmap.section-${s}.title`} /></h2>
                <p><FormattedMessage id={`roadmap.section-${s}.text`} /></p>
            </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default RoadMap
