import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import Header from 'src/components/Header/Header'

import './NotFound.sass'

const NotFound = () => {
  return (
    <div id="not-found" className="page">
      <Header />
      <section id="info">
        <h1>404</h1>
        <Link to="/" className="button">
          <FormattedMessage id="reload" />
        </Link>
      </section>
    </div>
  )
}

export default NotFound
