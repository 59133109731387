import React, { useRef } from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { useScrollOver } from '@gluedigital/scrollastic'
import Header from 'src/components/Header/Header'
import Typewriter from './Typewriter/Typewriter'
import PoweredBy from 'src/components/PoweredBy/PoweredBy'
import Footer from 'src/components/Footer/Footer'
import RoadMap from './RoadMap/RoadMap'
import CEO from './CEO/CEO'
import Intro from './Intro/Intro'
import Why from './Why/Why'
import Who from './Who/Who'

import './Home.sass'
import Requirements from './Requirements/Requirements'
import Cities from './Cities/Cities'
import Register from 'src/components/Register/Register'
import Banner from './Banner/Banner'

const Home = () => {
  const scrollable = useRef(null)
  const bannerSection = useScrollOver('#banner', scrollable)
  const introSection = useScrollOver('#intro', scrollable)
  const CEOSection = useScrollOver('#ceo-quests', scrollable)
  const whySection = useScrollOver('#why-participate', scrollable)
  const whoSection = useScrollOver('#who-are', scrollable)
  const requirementsSection = useScrollOver('#requirements', scrollable)
  const citiesSection = useScrollOver('#cities', scrollable)
  const registerSection = useScrollOver('#register', scrollable)

  return (
    <div id="home" key="home" ref={scrollable} className="page">
      <Header page="home" scrollable={scrollable} />
      <section id="hero-banner">
        <div className="hero-banner-wrapper">
          <Typewriter />
          <Link to="./#subscribe" className="button filled">
            <FormattedMessage id="header.link.subscribe" />
          </Link>
        </div>
        <div className="powered-wrapper">
          <PoweredBy withText />
        </div>
      </section>
      <Banner onView={bannerSection > 0.2} />
      <Intro onView={introSection > 0.3} />
      <RoadMap percent={useScrollOver('#roadmap', scrollable)} />
      <Why onView={whySection > 0.3} />
      <Who onView={whoSection > 0.3} />
      <CEO onView={CEOSection > 0.15} />
      <Requirements onView={requirementsSection > 0.3} />
      <Cities onView={citiesSection > 0.2} />
      <Register onView={registerSection > 0.2} />
      <Footer />
    </div>
  )
}

export default Home
