import React from 'react'
import { FormattedMessage } from 'react-intl'
import CalendarFormation from 'src/components/Calendar/CalendarFormation/CalendarFormation'
import CalendarHeader from 'src/components/Calendar/CalendarHeader/CalendarHeader'

import map from 'src/static/svg/map/Ciudades-icex.svg'

import './Cities.sass'

const Cities = ({onView}) => {

  return (
    <section id="cities" className={onView ? 'onView' : ''}>
      <h1 className="title">
        <FormattedMessage id="cities.title" />
      </h1>
      <div className="container">
        <CalendarHeader />
        <div className="split">
          <div className="item item-left">
            <CalendarFormation />
          </div>
          <div className="item item-right">
            <img src={map} alt="map illustration" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Cities
