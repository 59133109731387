import React from 'react'
import { FormattedMessage } from 'react-intl'
import images from './images'

import './PoweredBy.sass'

const PoweredBy = ({withText = false}) => {
  return (
    <div className="powered-by">
      <div className="container">
        {withText && <p><FormattedMessage id="home.hero-banner.powered-text" /></p>}
        <ul>
          <li><img src={images.icex} alt="icex logo" /></li>
          <li><img src={images.cesce} alt="cesce logo" /></li>
          <li><img src={images.adigital} alt="adigital logo" /></li>
          <li><img src={images.ico} alt="ico logo" /></li>
        </ul>
      </div>
    </div>
  )
}

export default PoweredBy
