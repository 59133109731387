import React from 'react'
import { FormattedMessage } from 'react-intl'
import Typed from 'react-typed'

import './Typewriter.sass'

const Typewriter = () => {

  return (
    <div className="typewriter">
      <h1>
        <FormattedMessage id="home.hero-banner.title" />
      </h1>
      <h2>
        <Typed
          strings={[
            'Internacionalización',
            'Financiación',
            'Digitalización',
            'Sostenibilidad',
            'Liderazgo',
            'Talento',
            'Innovación',
          ]}
          typeSpeed={40}
          backSpeed={50}
          loop />
      </h2>
    </div>
  )
}

export default Typewriter
